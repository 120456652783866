@import "themes";
@import "@nebular/theme/styles/globals";
@import "@uibakery/kit/styles.scss";
@import "responsive";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;700&display=swap");

@font-face {
  font-family: 'Gothic_A1';
  src: url('./assets/fonts/Gothic_A1/GothicA1-SemiBold.ttf') format('truetype');
  /* Add additional font formats if necessary (e.g., woff, woff2) */
}
$nb-enable-css-custom-properties: true;
$nb-enabled-themes: (
  light
);

@include nb-install() {
  @include nb-theme-global();
  @include install-bakery-styles();
  @include install-responsive-styles();
}

/* You can add global styles to this file, and also import other style files */

* {
  font-family: "Ubuntu", sans-serif;
}


nb-icon {
  cursor: pointer;
}

i:hover {
  cursor: pointer;
}

input {
  min-width: 100px;
}

th a {
  color: var(--blue) !important;
}

th a:hover {
  color: #834399 !important;
}

.p-tooltip-text {
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 8px 7px 8px 7px !important;
  border-radius: 3px !important;
}

.p-tooltip .p-tooltip-text {
  background-color: rgb(71, 71, 71) !important;
  color: white !important;
}

nb-layout .scrollable-container::-webkit-scrollbar-thumb {
  background-color: #555 !important;
}

nb-layout .scrollable-container::-webkit-scrollbar {
  width: 10px !important
}

/* Handle */
nb-layout .scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
}

nb-menu .menu-group, nb-menu .menu-item a {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #555;
}

nb-tabset .tabset {
  font-size: 1em;
  background: #f7f8f9;

  @media (max-width: 767.98px) {
    display: block !important;

    .tab-text {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .tab {
    .tab-link::before {
      background-color: transparent !important;
    }
  }

  .active {
    border-bottom-color: var(--tabset-tab-active-underline-color) !important;
    border-bottom-style: solid;
    border-bottom-width: 3px;
  }

  li:hover {
    border-bottom-color: #8aa7ff !important;
    border-bottom-style: solid;
    border-bottom-width: 3px;
  }
}

nb-tabset .tab-text {
  text-transform: initial;
  font-size: 15px !important;
}

.button-form {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

[nbInput].status-basic:disabled {
  color: #959494 !important;
}

i {
  font-size: 17px;
    margin-right: 5px;
    margin-left: 5px;
}

nb-spinner {
  z-index: 1000 !important;
}

nb-card-header {
  background: #f7f8f9;
  padding-top: 9px;
  padding-bottom: 9px;
}

nb-tabset .tab-link {
  color: #2f3145 !important;
}

nb-tabset .tab.active .tab-link {
  color: var(--blue) !important;
}

nb-tabset .tab-link:hover {
  color: var(--blue) !important;
}


nb-tab {
  min-height: 250px;
}

.p-datepicker table th {
  text-align: center;
  color: var(--blue);
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month, .p-datepicker-decade  {
  color: var(--blue) !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1em !important;
}

.p-text {
  font-size: 15pt;
  line-height: 2rem;
}

.title-text {
  color: var(--blue);
  font-size: 1em;
}

.small-p-text {
  font-size: 11pt;
  line-height: 1.7em;
}

.p-link {
  color: var(--blue);
  font-weight: 600;
  text-decoration: underline;
}

.p-link:hover {
  color: #6f42c1;
  cursor: pointer;
}

.table-form {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.thead-form {
  background: #f8f9fa;
  color: black;
}

.table-form caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.table-form tr {
  background-color: #d3d3d308;
  padding: .35em;
}

.table-form th,
.table-form td {
  border: 1px solid #dee2e6;
  border-width: 1px !important;
  padding: 1rem 1rem;
  text-align: center;
}

.table-form th {
  color: var(--blue);
  text-align: center;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px !important;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.search-global {
  width: 100% !important;
  margin-bottom: 2em !important;
  display: none;
}

.no-data-found{
  font-size: 15px;
}

@media screen and (min-width: 800px) {
  .responsive-image{
    width: 30%;
  }
  .search-table-outter {
    overflow-x: scroll;
    box-shadow: 0 0px 6px rgb(0 0 0 / 0%), 0 0px 6px rgb(0 0 0 / 11%);


    th,
    td {
      width: 200px;
    }

    th:first-child,
    td:first-child {
      width: 120px;
    }
  }

  .p-datatable .p-datatable-thead>tr>th,
  .p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #d7d7d7;
    border-width: 1px !important;
  }

  mfpaginator {
    display: flex;

    ul {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 800px) {
.responsive-image{
  width: 50%;
}
  .table-form {
    border: 0;
  }

  .table-form caption {
    font-size: 1.3em;
  }

  .table-form thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table-form tr {
    border-bottom: 3px solid #ddd;
    margin-bottom: .625em;
  }

  .table-form td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  .table-form td::before {

    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .table-form td:last-child {
    border-bottom: 0;
  }

  .table-responsive {
    display: inline-table;
  }


  .not-display {
    display: none;
  }

  .search-global {
    display: inline;
  }

}

nb-select .select-button {
  min-width: 0px !important;
}

.table thead th {
  vertical-align: inherit !important;
}

.badge {
  border-radius: 2px !important;
  padding: 0.25em 0.5rem !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}

.badge-primary {
  color: #007bff !important;
  background-color: #007bff42 !important;
}

.badge-secondary {
  color: #6c757d !important;
  background-color: #6c757d2e !important;
}

.badge-success {
  background: #C8E6C9 !important;
  color: #256029 !important;
}

.badge-info {
  color: #17a2b8 !important;
  background-color: #17a2b82b !important;
}

.badge-danger {
  background: #FFCDD2 !important;
  color: #c63737 !important;
}

.badge-warning {
  background: #FEEDAF !important;
  color: #8a5340 !important;
}

.spinner {
  width: 100%;
  padding-top: 50px;
  text-align: center;
}

.no-data-search {
  font-size: 0.85em;
  text-align: center;
  margin: 2em;
}

.p-dropdown {
  width: 100%;
}

.p-panel .p-panel-header .p-panel-title,
.p-dialog .p-dialog-header .p-dialog-title {
  color: var(--blue);
}

@keyframes p-progress-spinner-color {
  100% {
    stroke: #0057e7;
  }
}

.avatar {
  box-shadow: 0 0 5px grey;
  border-radius: 50%;
}


.input-photo-mb-5 {
  margin-bottom: 20px;
}

.input-photo-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.input-photo-input label {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.input-browse {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.p-progress-spinner-circle {
  stroke: var(--blue) !important;
}

nb-menu .menu-item a.active,
nb-menu .menu-item a.active .menu-icon {
  color: var(--blue) !important;
}

.reset-calendar {
  color: var(--blue);
}

.reset-calendar:hover {
  color:#6f42c1;
}

.card-wattzhub:hover {
  cursor: pointer;
}

.rectangle {
  height: 20px;
  width: 20px;
}

.modal-body {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.btn_choose-image {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6.5px 15px;
  cursor: pointer;
  margin-top: 10px;
}

.file-item {
  display: none;
}

.information-success {
  background-color: #28a745!important;
  color: white;
  padding: 20px;
  border-radius: 5px;
}

.information-danger {
  background-color: #dc3545!important;
  color: white;
  padding: 20px;
  border-radius: 5px;
}
